import { useState } from "react";
import { useDispatch } from "react-redux";
import { Table } from "antd";
import Pagination from "features/app/components/pagination/Pagination";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { PaginationType } from "features/app/utils/models/PaginationType";

import {
  ProductPriceAnalyze,
  TableDataTypeWidgetKey,
  useGetTableWidgetData,
  useWidget,
  Widget,
  widgetStaticData
} from "modules/dashboard";

import { LoadingIndicator } from "components";

import { appReducerActions } from "../../../../../../../store/reducers/appReducer";

import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

type Props<TKey extends keyof TableDataTypeWidgetKey> = {
  data: PaginationType<TableDataTypeWidgetKey[TKey][]>;
  widget: Widget;
};

const BaseTable = <TKey extends keyof TableDataTypeWidgetKey>(props: Props<TKey>) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { setProductMarketPrice } = appReducerActions;
  const { t } = useTranslation();

  const { setTableWidgetContentModal } = useWidget().methods;

  const { data, widget } = props;

  const { data: tableData, isLoading } = useGetTableWidgetData({
    page,
    widgetKey: widget?.key as keyof TableDataTypeWidgetKey,
    id: widget?.id
  });

  const { columns } = widgetStaticData[widget?.key];

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const footer = () => (
    <div className="p-2">
      <Pagination
        paginationProps={{
          current: tableData?.data?.data?.length > 0 ? tableData?.data?.current_page : data?.current_page,
          total: tableData?.data?.data?.length > 0 ? tableData?.data?.total : data?.total,
          onChange: onPageChange
        }}
      />
    </div>
  );

  const onRow = (record: ProductPriceAnalyze) => ({
    onClick: () => {
      setTableWidgetContentModal({
        tableWidgetModalVisible: true,
        widget,
        widgetProps: {
          productId: record?.product?.id,
          unitId: record?.unit?.id
        }
      });

      dispatch(
        setProductMarketPrice({
          unitId: record?.unit?.id,
          productId: record?.product?.id,
          defaultUnit: structuredClone(record?.unit),
          defaultProduct: structuredClone(record?.product)
        })
      );
    }
  });

  const tableColumns = columns?.map((col, index) => {
    if (index !== 0 && index !== columns?.length - 1) {
      return {
        ...col,
        title: t(`dashboard.${col?.title}`)
      };
    }
    return col;
  });

  return (
    <Table
      dataSource={tableData?.data?.data?.length > 0 ? tableData?.data?.data : data?.data}
      columns={tableColumns}
      className={styles.table}
      footer={footer}
      pagination={false}
      scroll={{ y: 380 }}
      onRow={onRow}
      locale={{
        emptyText: <TableEmpty />
      }}
      rowClassName="row"
      loading={{
        spinning: page > 1 && isLoading,
        indicator: LoadingIndicator
      }}
    />
  );
};

export default BaseTable;
