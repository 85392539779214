import React, { useEffect, useMemo, useState } from "react";
import { Spin } from "antd";
import dayjs from "dayjs";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import { dayjsFormats } from "features/app/utils/constants/dayjsFormats";
import { PaymentTypeEnum } from "features/app/utils/constants/paymentTypeEnum";
import { useGetCounterPartCalendarView } from "features/counterparts/service/queries";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { uid } from "uid";

import { useQueryParams } from "hooks/useQueryParams";

import { useGetPaymentCalendar } from "../../../../service/queries";
import { CalendarDailyModel, CalendarMonthlyModel } from "../../../../utils/models/paymentCalendarModel";

import CalendarEmpty from "./calend-empty/CalendarEmpty";
import PaymentCalendarCard from "./PaymentCalendarCard";
import PaymentCalendarCardAndHeader from "./PaymentCalendarCardAndHeader";
import PaymentCalendarHeader from "./PaymentCalendarHeader";

import styles from "./paymentCalendarContent.module.scss";

interface IProps {
  isCounterPart?: boolean;
}

const PaymentCalendarContent: React.FC<IProps> = ({ isCounterPart }) => {
  const { queries } = useQueryParams();
  const { view_type: viewType, min_date: startDate } = queries();
  const { data, isLoading } = useGetPaymentCalendar(Boolean(isCounterPart));
  const { data: counterPartCalendar, isLoading: counterPartCalendarLoading } = useGetCounterPartCalendarView(
    Boolean(isCounterPart)
  );

  const [calendarCardItems, setCalendarCardItems] = useState<CalendarDailyModel[]>([]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const newItems = Array.from(calendarCardItems);
    const [movedItem] = newItems.splice(source.index, 1);

    newItems.splice(destination.index, 0, movedItem);

    setCalendarCardItems(newItems);
  };

  const generateEmptyArr = useMemo(() => {
    const today = dayjs();
    const thisMonth = today?.get("month");
    const generateThisMonthFirstDay = `01.${thisMonth > 9 ? "" : 0}${thisMonth + 1}.${today?.get("year")}`;
    const day = dayjs(startDate || generateThisMonthFirstDay, dayjsFormats.DATE).get("day") as number;
    const arr = [];

    if (day === 0) {
      for (let i = 1; i <= 6; i++) {
        arr.push(i);
      }
    } else {
      for (let i = 1; i <= day - 1; i++) {
        arr.push(i);
      }
    }

    return arr;
  }, [startDate]);

  const content = useMemo(() => {
    const isMonth = viewType === PaymentTypeEnum.MONTHLY;

    if (viewType === PaymentTypeEnum.ANNUAL || isMonth) {
      const notDailyData = (isCounterPart ? counterPartCalendar : data) as CalendarMonthlyModel[];

      return (
        <div className={styles.not_daily}>
          {notDailyData?.map(item => (
            <PaymentCalendarCardAndHeader key={item?.date} {...item} isCompanyPerson={isCounterPart} />
          ))}
        </div>
      );
    }
    const dailyData = calendarCardItems as CalendarDailyModel[];

    return (
      <div className={styles.daily}>
        <PaymentCalendarHeader />
        <div className={styles.daily__content}>
          {generateEmptyArr?.map(() => <div key={uid()} />)}
          <DragDropContext onDragEnd={onDragEnd}>
            {dailyData?.map((item, index) => (
              <Droppable key={item?.date} droppableId={item?.date}>
                {provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <Draggable draggableId={item?.date} index={index}>
                      {provided => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <PaymentCalendarCard
                            key={item?.date}
                            date={item?.date}
                            data={item?.data}
                            index={index + 1}
                            isCompanyPerson={isCounterPart}
                          />
                        </div>
                      )}
                    </Draggable>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </DragDropContext>
        </div>
      </div>
    );
  }, [viewType, calendarCardItems, generateEmptyArr]);

  useEffect(() => {
    if (isCounterPart) {
      setCalendarCardItems(counterPartCalendar);
    } else {
      setCalendarCardItems(data);
    }
  }, [counterPartCalendar, data, isCounterPart]);

  return (
    <div className={`${styles.calendar} h-full`}>
      <Spin spinning={isCounterPart ? counterPartCalendarLoading : isLoading} indicator={LoadingIndicator}>
        {(!isCounterPart && (!data || data?.length === 0)) ||
        (isCounterPart && (!counterPartCalendar || counterPartCalendar?.length === 0)) ? (
          <div className="p-16">
            <CalendarEmpty />
          </div>
        ) : (
          content
        )}
      </Spin>
    </div>
  );
};

export default PaymentCalendarContent;
