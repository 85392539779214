import React from "react";
import { useTranslation }  from "react-i18next";

import TransferNoDataIcon from "../../../../assets/icons/TransferNoDataIcon";

import styles from "./transferNoData.module.scss";

const TransferNoData: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.container}>
      <TransferNoDataIcon />
      <span>
        <h1>{t("warehouse.O'tkazma mahsulotlari mavjud emas")}</h1>
        <p>{t("warehouse.Omborxona tanlang va o'tkazma qilishni boshlang")}</p>
      </span>
    </div>
  );
};

export default TransferNoData;
