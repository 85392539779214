import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UseMutationResult } from "@tanstack/react-query";
import { Button } from "antd";
import dayjs, { Dayjs } from "dayjs";
import CustomFieldIcon from "features/app/assets/icons/CustomFieldIcon";
import MainWarehouseIcon from "features/app/assets/icons/MainWarehouseIcon";
import FilterTreeSelect from "features/app/components/filter-drawer/filter-tree-select/filter-tree-select";
import { useChangeTableConfig } from "features/app/service/mutation";
import { CustomFieldTypesEnum } from "features/app/utils/enums/customFieldTypesEnum";
import CreditCardIcon from "features/payment/assets/icons/CreditCardIcon";
import ArrowSquareDownIcon from "features/settings/assets/icons/ArrowSquareDownIcon";
import TagIcon from "features/settings/assets/icons/TagIcon";
import { useGetPaymentTypes, useGetVatView } from "features/settings/service/queries";
import { routeSubmodules } from "features/supply/utils/constants/routeSubmodules";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { useRoutePermissions } from "hooks/useRoutePermissions";

import { colors } from "modules/common";

import { ConditionalRender } from "components";

import { useQueryParams } from "../../../../../hooks/useQueryParams";
import DownloadIcon from "../../../../app/assets/icons/DownloadIcon";
import ProjectIcon from "../../../../app/assets/icons/ProjectIcon";
import FilterDateRange from "../../../../app/components/filter-drawer/filter-date-range/FilterDateRange";
import FilterPricesRange from "../../../../app/components/filter-drawer/filter-prices-range/FilterPricesRange";
import FilterSelect from "../../../../app/components/filter-drawer/filter-select/FilterSelect";
import FilterDrawer from "../../../../app/components/filter-drawer/FilterDrawer";
import FiltersCount from "../../../../app/components/filter-drawer/filters-count/FiltersCount";
import {
  useGetCompanyPersons,
  useGetCustomFieldSelect,
  useGetTableConfigs,
  useGetUsersSelect,
  useProjectSelect
} from "../../../../app/service/queries";
import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";
import { tableData } from "../../../../app/utils/constants/defaultTableData";
import { queryParamsKeys } from "../../../../app/utils/constants/queryParamsKeys";
import { tableConfigKeys } from "../../../../app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "../../../../app/utils/enums/customFieldLocationEnum";
import { ImpostStatusEnums } from "../../../../app/utils/enums/impostStatusEnums";
import { filterColumns } from "../../../../app/utils/helpers/filterColumns";
import { generateExcelPayload } from "../../../../app/utils/helpers/generateExcelPayload";
import UsersIcon from "../../../../payment/assets/icons/UsersIcon";
import ZapIcon from "../../../../projects/assets/icons/ZapIcon";
import FilterLinesIcon from "../../../../warehouse/assets/icons/FilterLinesIcon";
import { useGetWarehouseSelect } from "../../../../warehouse/service/query";
import { StatusEnums } from "../../../utils/enums/statusEnums";
import { orderPaymentStatus } from "../../../utils/helpers/orderPaymentFunc";

import styles from "./filterDrawer.module.scss";

const queryStringArr = [
  queryParamsKeys.MIN_PAYMENT_DATE,
  queryParamsKeys.MAX_PAYMENT_DATE,
  queryParamsKeys.MAX_ORDERED_DATE,
  queryParamsKeys.MAX_DELIVERY_DATE,
  queryParamsKeys.MIN_ORDERED_DATE,
  queryParamsKeys.MIN_DELIVERY_DATE,
  queryParamsKeys.AGENT_IDS,
  queryParamsKeys.STATUSES,
  queryParamsKeys.MIN_AMOUNT,
  queryParamsKeys.MAX_AMOUNT,
  queryParamsKeys.PROJECT_IDS,
  queryParamsKeys.WAREHOUSE_IDS,
  queryParamsKeys.PAYMENT_STATUSES,
  queryParamsKeys.COMPANY_PERSON_IDS,
  queryParamsKeys.CONFIRMATION_PAYMENTS,
  queryParamsKeys.CUSTOM_FIELDS,
  queryParamsKeys.PAYMENT_TYPE_IDS,
  queryParamsKeys.DISCOUNT,
  queryParamsKeys.VAT
];

interface IProps {
  customFieldEnum?: CustomFieldLocationEnum;
  tableKey?: tableConfigKeys;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportExcelMutate?: UseMutationResult<any, unknown, any, unknown>;
}

const FilterDrawerParts: React.FC<IProps> = ({ tableKey, exportExcelMutate, customFieldEnum }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { queries } = useQueryParams();
  const {
    search,
    min_payment_date,
    max_payment_date,
    min_delivery_date,
    max_delivery_date,
    min_ordered_date,
    max_ordered_date
  } = queries();
  const { actions } = useRoutePermissions("Ta'minot", routeSubmodules);
  const partyActions = actions("Partiyalar");
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [usersSelected, setUsersSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.AGENT_IDS] as string[] | null
  );
  const [companyPersonsSelected, setCompanyPersonsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.COMPANY_PERSON_IDS] as string[] | null
  );
  const [statusSelected, setStatusSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.STATUSES] as string[] | null
  );
  const [paymentStatusSelected, setPaymentStatusSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PAYMENT_STATUSES] as string[] | null
  );
  const [confirmationStatusSelected, setConfirmationStatusSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.CONFIRMATION_PAYMENTS] as string[] | null
  );
  const [startPriceValue, setStartPriceValue] = useState<string>(
    queryString.parse(location.search)[queryParamsKeys.MIN_AMOUNT] as string
  );
  const [endPriceValue, setEndPriceValue] = useState<string>(
    queryString.parse(location.search)[queryParamsKeys.MAX_AMOUNT] as string
  );
  const [discountValue, setDiscountValue] = useState<string[] | null>(
    queryString.parse(location.search)[queryParamsKeys.DISCOUNT] as string[] | null
  );
  const [vatValue, setVatValue] = useState<string[] | null>(
    queryString.parse(location.search)[queryParamsKeys.VAT] as string[] | null
  );
  const [dateValue, setDateValue] = useState<null | (Dayjs | null)[]>([
    min_payment_date ? dayjs(min_payment_date, dayjsFormats.DATE) : null,
    max_payment_date ? dayjs(max_payment_date, dayjsFormats.DATE) : null
  ]);
  const [deliveryDateValue, setDeliveryDateValue] = useState<null | (Dayjs | null)[]>([
    min_delivery_date ? dayjs(min_delivery_date, dayjsFormats.DATE) : null,
    max_delivery_date ? dayjs(max_delivery_date, dayjsFormats.DATE) : null
  ]);
  const [orderedDateValue, setOrderedDateValue] = useState<null | (Dayjs | null)[]>([
    min_ordered_date ? dayjs(min_ordered_date, dayjsFormats.DATE) : null,
    max_ordered_date ? dayjs(max_delivery_date, dayjsFormats.DATE) : null
  ]);
  const [warehouseSelected, setWarehouseSelected] = useState(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.WAREHOUSE_IDS] as string[] | null
  );
  const [projectSelected, setProjectSelected] = useState(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PROJECT_IDS] as string[] | null
  );
  const [paymentTypesSelected, setPaymentTypesSelected] = useState(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PAYMENT_TYPE_IDS] as string[] | null
  );

  const tableDefaultData = tableKey ? tableData(tableKey) : undefined;

  const { data: tableConfigData } = useGetTableConfigs(tableKey, tableDefaultData);
  const { data: customFields } = useGetCustomFieldSelect(customFieldEnum ? [customFieldEnum] : []);
  const { data: adminsSelect } = useGetUsersSelect(open);
  const { data: counterpartsSelect } = useGetCompanyPersons();
  const { data: warehouseSelect } = useGetWarehouseSelect(true);
  const { data: projects } = useProjectSelect({ enabled: open });
  const { data: paymentTypes } = useGetPaymentTypes();
  const { data: vats } = useGetVatView();

  const changeTableConfiguration = useChangeTableConfig(tableKey!, true, true);

  const [customFieldStates, setCustomFieldStates] = useState<{ [key: string]: string[] | null }>({});

  const selectableCustomFields = customFields?.filter(
    customField => customField?.type === "select" || customField?.type === "label"
  );

  const handleCustomFieldChange = (id: string, value: string[] | null) => {
    setCustomFieldStates(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const aggregatedCustomFieldsSelected = useMemo(() => Object.values(customFieldStates).flat(), [customFieldStates]);

  const admins = useMemo(() => adminsSelect?.map(item => ({ name: item?.full_name, ...item })), [adminsSelect]);

  const counterparts = useMemo(() => counterpartsSelect?.map(item => ({ ...item })), [counterpartsSelect]);

  const statusData = useMemo(
    () => [
      {
        id: StatusEnums.OPEN,
        name: `${t("Supply.Yangi")}`
      },
      {
        id: StatusEnums.ORDERED,
        name: `${t("Supply.Partiya qilingan")}`
      },
      {
        id: StatusEnums.PENDING,
        name: `${t("Supply.Kutilmoqda")}`
      },
      {
        id: StatusEnums.PASSIVE,
        name: `${t("Supply.Qoralama")}`
      },
      {
        id: StatusEnums.RECIEVED,
        name: `${t("Supply.Yopilgan")}`
      },
      {
        id: StatusEnums.REJECTED,
        name: `${t("Supply.Bekor qilinagan")}`
      },
      {
        id: StatusEnums.BUYING,
        name: t("Supply.Sotib olinmoqda")
      }
    ],
    []
  );

  const confirmationStatusData = useMemo(
    () => [
      {
        id: ImpostStatusEnums.PENDING,
        name: `${t("Supply.Kutilmoqda")}`
      },
      {
        id: ImpostStatusEnums.RECIEVED,
        name: `${t("Supply.Tasdiqlangan")}`
      },
      {
        id: ImpostStatusEnums.REJECTED,
        name: `${t("Supply.Rad etilgan")}`
      }
    ],
    []
  );

  const paymentStatusData = useMemo(
    () => [
      {
        id: orderPaymentStatus.PAID,
        name: `${t("Supply.To'langan")}`
      },
      {
        id: orderPaymentStatus.PARTLY,
        name: `${t("Supply.Qisman")}`
      },
      {
        id: orderPaymentStatus.UNPAID,
        name: `${t("Supply.To'lanmagan")}`
      }
    ],
    []
  );

  const discountData = useMemo(
    () => [
      {
        id: "true",
        name: t("Supply.Chegirmali")
      },
      {
        id: "false",
        name: t("Supply.Chegirmasiz")
      }
    ],
    []
  );
  const vatData = useMemo(
    () =>
      vats?.map(el => ({
        id: String(el.id),
        name: `${el?.value}%`
      })),
    [vats]
  );

  const onOpenFilter = () => {
    setOpen(true);
  };

  const onExcelExport = () => {
    const data = filterColumns({
      customFields,
      tableConfig: tableConfigData
    });

    let result: Array<Record<string, string>> = [];

    data?.forEach(item => {
      if (typeof item.title !== "object") {
        if (String(item.id)?.includes("amount")) {
          result = [
            ...result,
            {
              key: String(item?.id),
              name: item?.title
            },
            { key: "currency.symbol", name: "Valyuta" }
          ];
        } else {
          result.push({
            key: String(item?.id),
            name: item?.title
          });
        }
      }
    });

    if (result) {
      exportExcelMutate?.mutate(
        generateExcelPayload({
          search,
          columns: result?.sort((a, b) => {
            if (a.key === "id") return -1;
            if (b.key === "id") return 1;
            return 0;
          }),
          agent_ids: usersSelected,
          statuses: statusSelected,
          max_amount: endPriceValue,
          min_amount: startPriceValue,
          project_ids: projectSelected,
          warehouse_ids: warehouseSelected,
          payment_statuses: paymentStatusSelected,
          company_person_ids: companyPersonsSelected,
          confirmation_payments: confirmationStatusSelected
        })
      );
    }
  };

  const filterButton = (
    <Button onClick={onOpenFilter}>
      <FilterLinesIcon /> {t("Supply.Filter")}
      <FiltersCount queryStringArr={queryStringArr} />
    </Button>
  );

  const footerContent =
    tableKey && partyActions?.upload ? (
      <Button onClick={onExcelExport} className={styles.downloadBtn} loading={exportExcelMutate?.isLoading}>
        <DownloadIcon /> {t("Supply.Yuklab olish")}
      </Button>
    ) : (
      <span />
    );

  const onAfterSubmit = (filter?: string) => {
    changeTableConfiguration.mutate({
      filter: filter!,
      key: tableKey!
    });
  };

  const customFieldIcons: Record<string, React.ReactNode> = {
    [CustomFieldTypesEnum.SELECT]: <ArrowSquareDownIcon />,
    [CustomFieldTypesEnum.LABEL]: <TagIcon />
  };

  const searchParams = queryString.parse(location.search, { arrayFormat: "bracket" });
  const fieldValues = searchParams[queryParamsKeys.CUSTOM_FIELDS];

  useEffect(() => {
    if (selectableCustomFields && open) {
      const initialCustomFieldStates: { [key: string]: string[] | null } = {};

      selectableCustomFields.forEach(field => {
        if (fieldValues) {
          const valuesArray = Array.isArray(fieldValues) ? fieldValues : [fieldValues];

          initialCustomFieldStates[field.id] = valuesArray.filter(value =>
            field?.custom_field_options?.some(option => String(option.id) === value)
          ) as string[];
        } else {
          initialCustomFieldStates[field.id] = [];
        }
      });

      setCustomFieldStates(initialCustomFieldStates);
    }
  }, [open]);

  useEffect(() => {
    if (tableConfigData?.filter && tableConfigData?.filter?.length > 0) {
      const searchParams = new URLSearchParams(window.location.search);

      const filterParams = new URLSearchParams(tableConfigData.filter);

      filterParams.forEach((value, key) => {
        const existingValues = searchParams.getAll(key);

        if (!existingValues.includes(value)) {
          searchParams.append(key, value);
        }
      });

      const newSearchParams = searchParams.toString();

      navigate({
        search: `?${newSearchParams}`
      });
    }
  }, [tableConfigData, navigate]);

  const parseQueryParams = (key: string) =>
    queryString.parse(window.location.search, { arrayFormat: "bracket" })[key] as string[] | null;
  const parseSingleQueryParams = (key: string) =>
    queryString.parse(window.location.search, { arrayFormat: "bracket" })[key] as string;

  useEffect(() => {
    setUsersSelected(parseQueryParams(queryParamsKeys.AGENT_IDS));
    setProjectSelected(parseQueryParams(queryParamsKeys.PROJECT_IDS));
    setCompanyPersonsSelected(parseQueryParams(queryParamsKeys.COMPANY_PERSON_IDS));
    setStatusSelected(parseQueryParams(queryParamsKeys.STATUSES));
    setPaymentStatusSelected(parseQueryParams(queryParamsKeys.PAYMENT_STATUSES));
    setConfirmationStatusSelected(parseQueryParams(queryParamsKeys.CONFIRMATION_PAYMENTS));
    setWarehouseSelected(parseQueryParams(queryParamsKeys.WAREHOUSE_IDS));
    setStartPriceValue(parseSingleQueryParams(queryParamsKeys.MIN_AMOUNT));
    setEndPriceValue(parseSingleQueryParams(queryParamsKeys.MAX_AMOUNT));
    setDiscountValue(parseQueryParams(queryParamsKeys.DISCOUNT));
    setVatValue(parseQueryParams(queryParamsKeys.VAT));
    setDeliveryDateValue([
      parseSingleQueryParams(queryParamsKeys.MIN_DELIVERY_DATE) as never,
      parseSingleQueryParams(queryParamsKeys.MAX_DELIVERY_DATE) as never
    ]);
    setOrderedDateValue([
      parseSingleQueryParams(queryParamsKeys.MIN_ORDERED_DATE) as never,
      parseSingleQueryParams(queryParamsKeys.MAX_ORDERED_DATE) as never
    ]);
    setDateValue([
      parseSingleQueryParams(queryParamsKeys.MIN_PAYMENT_DATE) as never,
      parseSingleQueryParams(queryParamsKeys.MAX_PAYMENT_DATE) as never
    ]);
  }, [window.location.search]);

  return (
    <FilterDrawer
      open={open}
      onAfterClear={onAfterSubmit}
      onAfterSubmit={onAfterSubmit}
      setOpen={setOpen}
      height="max-content"
      filterButton={filterButton}
      footerContent={footerContent}
      queryStringArr={queryStringArr}
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.COMPANY_PERSON_IDS,
          selectedOptions: companyPersonsSelected
        },

        {
          queryKey: queryParamsKeys.AGENT_IDS,
          selectedOptions: usersSelected
        },
        {
          queryKey: queryParamsKeys.STATUSES,
          selectedOptions: statusSelected
        },
        {
          queryKey: queryParamsKeys.PAYMENT_STATUSES,
          selectedOptions: paymentStatusSelected
        },
        {
          queryKey: queryParamsKeys.CONFIRMATION_PAYMENTS,
          selectedOptions: confirmationStatusSelected
        },
        {
          queryKey: queryParamsKeys.WAREHOUSE_IDS,
          selectedOptions: warehouseSelected
        },
        {
          queryKey: queryParamsKeys.PROJECT_IDS,
          selectedOptions: projectSelected
        },
        {
          queryKey: queryParamsKeys.CUSTOM_FIELDS,
          selectedOptions: aggregatedCustomFieldsSelected as string[]
        },
        {
          queryKey: queryParamsKeys.PAYMENT_TYPE_IDS,
          selectedOptions: paymentTypesSelected
        },
        {
          queryKey: queryParamsKeys.DISCOUNT,
          selectedOptions: discountValue
        },
        {
          queryKey: queryParamsKeys.VAT,
          selectedOptions: vatValue
        }
      ]}
      selectedValueArr={[
        {
          queryKey: queryParamsKeys.MIN_AMOUNT,
          selectedValue: startPriceValue
        },
        {
          queryKey: queryParamsKeys.MAX_AMOUNT,
          selectedValue: endPriceValue
        }
      ]}
      dateValueArr={[
        {
          queryKey: queryParamsKeys.MIN_PAYMENT_DATE,
          selectedValue: dateValue && dateValue[0]
        },
        {
          queryKey: queryParamsKeys.MAX_PAYMENT_DATE,
          selectedValue: dateValue && dateValue[1]
        },
        {
          queryKey: queryParamsKeys.MIN_DELIVERY_DATE,
          selectedValue: deliveryDateValue && deliveryDateValue[0]
        },
        {
          queryKey: queryParamsKeys.MAX_DELIVERY_DATE,
          selectedValue: deliveryDateValue && deliveryDateValue[1]
        },
        {
          queryKey: queryParamsKeys.MIN_ORDERED_DATE,
          selectedValue: orderedDateValue && orderedDateValue[0]
        },
        {
          queryKey: queryParamsKeys.MAX_ORDERED_DATE,
          selectedValue: orderedDateValue && orderedDateValue[1]
        }
      ]}
      setDateValue={[setDateValue, setDeliveryDateValue, setOrderedDateValue]}
      setSelectedValueArr={[setStartPriceValue, setEndPriceValue]}
      setSelectedOptionsArr={
        [
          setCompanyPersonsSelected,
          setUsersSelected,
          setStatusSelected,
          setPaymentStatusSelected,
          setConfirmationStatusSelected,
          setWarehouseSelected,
          setProjectSelected,
          setCustomFieldStates,
          setPaymentTypesSelected,
          setDiscountValue,
          setVatValue
        ] as never
      }
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2 text-base font-medium text-gray-800">
            <FilterLinesIcon stroke={colors.GRAY_800} /> {t("Supply.Umumiy filterlar")}
          </div>
          <div className={styles.content}>
            <FilterSelect
              multiple="multiple"
              data={warehouseSelect}
              placeholder={t("Supply.Omborxona")}
              icon={<MainWarehouseIcon />}
              selectedOptions={warehouseSelected}
              setSelectedOptions={setWarehouseSelected}
            />
            <FilterTreeSelect
              data={projects}
              multiple={true}
              placeholder={t("Supply.Loyiha")}
              icon={<ProjectIcon />}
              selectedOptions={projectSelected}
              setSelectedOptions={setProjectSelected}
              showSearch
            />
            <FilterSelect
              showSearch
              data={admins}
              multiple="multiple"
              icon={<UsersIcon />}
              placeholder={t("Supply.Vositachi")}
              selectedOptions={usersSelected}
              setSelectedOptions={setUsersSelected}
            />
            <FilterSelect
              showSearch
              data={counterparts}
              multiple="multiple"
              icon={<UsersIcon />}
              placeholder={t("Supply.Kontragent")}
              selectedOptions={companyPersonsSelected}
              setSelectedOptions={setCompanyPersonsSelected}
            />
            <FilterSelect
              showSearch
              data={statusData}
              icon={<ZapIcon />}
              multiple="multiple"
              placeholder={t("Supply.Status")}
              selectedOptions={statusSelected}
              setSelectedOptions={setStatusSelected}
            />
            <FilterSelect
              showSearch
              icon={<ZapIcon />}
              multiple="multiple"
              data={paymentStatusData}
              placeholder={t("Supply.To'lov statusi")}
              selectedOptions={paymentStatusSelected}
              setSelectedOptions={setPaymentStatusSelected}
            />
            <FilterSelect
              showSearch
              icon={<ZapIcon />}
              multiple="multiple"
              placeholder={t("Supply.Tasdiqlash")}
              data={confirmationStatusData}
              selectedOptions={confirmationStatusSelected}
              setSelectedOptions={setConfirmationStatusSelected}
            />
            <FilterSelect
              selectedOptions={paymentTypesSelected}
              setSelectedOptions={setPaymentTypesSelected}
              data={paymentTypes}
              placeholder={t("Supply.To'lov turi")}
              multiple="multiple"
              icon={<CreditCardIcon />}
              showSearch
            />

            <FilterDateRange
              key="payment_date"
              value={dateValue}
              setValue={setDateValue}
              placeholder={t("Supply.To'lov sanasi")}
            />
            <FilterDateRange
              key="delivery_date"
              value={deliveryDateValue}
              setValue={setDeliveryDateValue}
              placeholder={t("Supply.Yetkazilish sanasi")}
            />
            <FilterDateRange
              key="ordered_date"
              value={orderedDateValue}
              setValue={setOrderedDateValue}
              placeholder={t("Supply.Buyurtma sanasi")}
            />
            <FilterPricesRange
              placeholder={t("Supply.Umumiy summa")}
              endPriceValue={endPriceValue}
              startPriceValue={startPriceValue}
              setEndPriceValue={setEndPriceValue}
              setStartPriceValue={setStartPriceValue}
            />
            <FilterSelect
              selectedOptions={discountValue}
              setSelectedOptions={setDiscountValue}
              data={discountData}
              multiple="multiple"
              placeholder={t("Supply.Chegirma")}
            />
            <FilterSelect
              selectedOptions={vatValue}
              data={vatData}
              setSelectedOptions={setVatValue}
              multiple="multiple"
              placeholder={t("Supply.NDS")}
            />
          </div>
        </div>
        <ConditionalRender if={selectableCustomFields && selectableCustomFields.length! > 0}>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2 text-base font-medium text-gray-800">
              <CustomFieldIcon stroke={colors.GRAY_800} width={20} /> {t("Supply.O'zgaruvchi filterlar")}
            </div>
            <div className={styles.content}>
              {selectableCustomFields?.map(el => (
                <FilterSelect
                  key={el.id}
                  selectedOptions={customFieldStates[el.id]}
                  setSelectedOptions={value => handleCustomFieldChange(String(el.id), value)}
                  data={el.custom_field_options as never}
                  multiple="multiple"
                  icon={customFieldIcons[el.type]}
                  placeholder={el.name[i18n.language]}
                  showSearch
                />
              ))}
            </div>
          </div>
        </ConditionalRender>
      </div>
    </FilterDrawer>
  );
};

export default FilterDrawerParts;
