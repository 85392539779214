import React from "react";
import { Popover, PopoverProps } from "antd";

import styles from "./popoverShortName.module.scss";

type Props = {
  title?: string;
  length?: number;
  getPopoverContainer?: () => HTMLElement;
  className?: string;
};

const PopoverShortName: React.FC<Props> = ({ title, length = 10, getPopoverContainer, className }) => {
  return (
    <div className={className}>
      {title && title.length > length ? (
        <Popover getPopupContainer={getPopoverContainer} title={title} overlayClassName={styles.popover}>
          {title.substring(0, length)}...
        </Popover>
      ) : (
        title
      )}
    </div>
  );
};

export default PopoverShortName;
