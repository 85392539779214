import React, { SetStateAction, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { FormInstance, Radio, Spin } from "antd";
import { useGetCashMoneyPaymentTypeSelect } from "features/payment/service/queries";
import { useTranslation } from "react-i18next";

import { numberRound } from "modules/common";

import { useAppSelector } from "../../../../../../hooks/redux";
import { useAuth } from "../../../../../../modules/auth/hooks";
import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import { RU } from "../../../../../app/utils/constants/languages";
import { isEmptyArr } from "../../../../../app/utils/helpers/isEmptyArr";
import { localeFormatter } from "../../../../../app/utils/helpers/localeFormatter";
import CheckboxBaseIcon from "../../../../assets/icons/CheckboxBaseIcon";

import styles from "./secondStep.module.scss";

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  isExpectedPayment?: boolean;
  form?: FormInstance;
  autoAdvance: boolean;
  setAutoAdvance: React.Dispatch<SetStateAction<boolean>>;
};

const SecondStep: React.FC<Props> = ({ setStep, isExpectedPayment, form, autoAdvance, setAutoAdvance }) => {
  const dispatch = useDispatch();
  const { t }  = useTranslation();
  const { currencies } = useAuth();
  const { setIncomeExpenseModal, setPayModal } = paymentReducerActions;
  const payModal = useAppSelector(state => state.paymentReducer.pay_modal);
  const { visible, type, cash, payment_type, currency, companyPersonId, isVisibleMoreInfo, ...other } = useAppSelector(
    state => state.paymentReducer.income_expense_modal
  );

  const { payment_type: payType } = useAppSelector(state => state.paymentReducer.pay_modal);

  const {
    data: paymentTypes,
    isLoading: isLoadingPaymentTypes,
    isFetching
  } = useGetCashMoneyPaymentTypeSelect({
    type: isExpectedPayment ? payModal?.type : type,
    cashId: isExpectedPayment ? payModal?.cash?.id : cash?.id,
    projectId: isExpectedPayment ? payModal?.form_data?.project_id : undefined,
    enabled: Boolean(type && (isExpectedPayment ? payModal?.cash?.id : cash?.id))
  });

  const onClickPayment = (value: string) => {
    const [currencyId, paymentTypeId] = value.split("-");

    const paymentType = paymentTypes?.find(item => item.id === Number(paymentTypeId));
    const { company_person, ...rest } = paymentType!;
    const selectCurrency = paymentType?.currencies?.find(item => item.id === Number(currencyId));

    form && form.setFieldValue("project_id", undefined);
    form && form.setFieldValue("order_ids", []);

    if (!isExpectedPayment) {
      dispatch(
        setIncomeExpenseModal({
          cash,
          type,
          visible,
          companyPersonId,
          isVisibleMoreInfo,
          currency: selectCurrency,
          payment_type: paymentType,
          ...other
        })
      );
    } else {
      dispatch(
        setPayModal({
          type: payModal.type,
          cash: payModal.cash,
          currency: selectCurrency,
          visible: payModal.visible,
          payment_type: {
            company_person: payType?.company_person ? payType?.company_person : {},
            ...rest
          },
          queryKeys: payModal?.queryKeys,
          form_data: payModal.form_data,
          isTemplate: payModal.isTemplate
        })
      );

      const activeCurrency = currencies?.find(item => item.id === selectCurrency?.id);

      form?.setFieldValue(
        "amount",
        localeFormatter(String(numberRound((payModal?.form_data?.amount || 1) / (activeCurrency?.amount || 1))))
      );
    }
    setAutoAdvance(true);
    setStep(state => state + 1);
  };

  const cashData = useMemo(() => {
    if (isExpectedPayment) return payModal?.cash;
    return cash;
  }, [cash, payModal.cash, isExpectedPayment]);

  const paymentTypeValue = () => {
    if (isExpectedPayment) return `${payModal?.currency?.id}-${payModal?.payment_type?.id}`;
    return `${currency?.id}-${payment_type?.id}`;
  };

  useEffect(() => {
    if (paymentTypes && autoAdvance) {
      if (paymentTypes?.length === 1 && paymentTypes[0]?.currencies?.length === 1) {
        form && form.setFieldValue("project_id", undefined);

        if (!isExpectedPayment) {
          dispatch(
            setIncomeExpenseModal({
              cash,
              type,
              visible,
              companyPersonId,
              isVisibleMoreInfo,
              currency: paymentTypes[0].currencies[0],
              payment_type: paymentTypes[0],
              ...other
            })
          );
        } else {
          const { company_person, ...rest } = paymentTypes[0];

          dispatch(
            setPayModal({
              type: payModal.type,
              cash: payModal.cash,
              currency: paymentTypes[0].currencies[0],
              visible: payModal.visible,
              payment_type: {
                company_person: payType?.company_person ? payType?.company_person : {},
                ...rest
              },
              queryKeys: payModal?.queryKeys,
              form_data: payModal.form_data,
              isTemplate: payModal.isTemplate
            })
          );

          const activeCurrency = currencies?.find(item => item.id === paymentTypes[0].currencies[0]?.id);

          form?.setFieldValue(
            "amount",
            localeFormatter(String(numberRound((payModal?.form_data?.amount || 1) / (activeCurrency?.amount || 1))))
          );
        }

        setStep(state => {
          if (state === 1) {
            return state + 1;
          }
          return state - 1;
        });
      }
    }
  }, [isFetching, autoAdvance]);

  return (
    <Spin spinning={isLoadingPaymentTypes || isFetching} indicator={LoadingIndicator}>
      <div className={styles.payment}>
        <div className={styles.cash}>
          <div className={styles.name}>
            <h4>{cashData?.name}</h4>
            <p>{t("payment.Kassa")}</p>
          </div>
          <div className={styles.currency}>
            {cashData?.currencies?.map(currency => (
              <div className={styles.currency__item} key={currency.id}>
                <img src={currency.icon} alt={currency.symbol} />
                <span>
                  {currency.amount?.toLocaleString(RU)} {currency.symbol}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.bottom}>
          {paymentTypes?.map(item => (
            <div key={item.id} className={styles.item}>
              <ConditionalRender if={!isEmptyArr(item?.currencies)}>
                <Radio.Group optionType="button" value={paymentTypeValue()}>
                  <div className={styles.item__left}>{item.name}</div>
                  {item?.currencies?.map(currency => (
                    <Radio
                      key={currency.id}
                      value={`${currency.id}-${item.id}`}
                      onClick={() => onClickPayment(`${currency.id}-${item.id}`)}
                    >
                      <div className={styles.payment_currency}>
                        <img src={currency.icon} alt={currency?.symbol} />
                        <span>
                          {currency.amount?.toLocaleString(RU)} {currency.symbol}
                        </span>
                      </div>
                      <CheckboxBaseIcon />
                    </Radio>
                  ))}
                </Radio.Group>
              </ConditionalRender>
            </div>
          ))}
        </div>
      </div>
    </Spin>
  );
};

export default SecondStep;
