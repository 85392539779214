import React from "react";
import { useDispatch } from "react-redux";
import { Button, Form, FormInstance } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../hooks/redux";
import { warehouseOrderActions } from "../../../../../../store/reducers/warehouseOrderReducer";
import CloseIcon from "../../../../../app/assets/icons/CloseIcon";
import { RU } from "../../../../../app/utils/constants/languages";
import { ImpostStatusEnums } from "../../../../../app/utils/enums/impostStatusEnums";
import PuzzleIcon from "../../../../assets/icons/PuzzleIcon";
import { OrderEnum } from "../../../../utils/constants/OrderEnum";
import { OneOrderModel } from "../../../../utils/models/oneOrderModel";

import LeftGroup from "./LeftGroup";
import LeftTable from "./LeftTable";

import styles from "./left.module.scss";

type Props = {
  form: FormInstance;
  data: OneOrderModel[] | undefined;
  isLoading: boolean;
};

const { Item, useWatch } = Form;

const Left: React.FC<Props> = ({ form, data, isLoading }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isGroupPerson: boolean = useWatch("isGroupPerson", form);
  const warehouseProducts: { id: number; quantity: string }[] = useWatch("warehouse_products", form);

  const { data: order } = useAppSelector(state => state.warehouseOrderReducer.view);

  const isAccept = useAppSelector(state => state.warehouseOrderReducer.accept.visible);

  const { setIsAccept } = warehouseOrderActions;

  const onToggleAccept = () => {
    dispatch(setIsAccept(!isAccept));

    if (!isAccept) {
      warehouseProducts?.map((_, index) => form.resetFields([["warehouse_products", index, "quantity"]]));
    }
  };

  const checkStatus =
    order?.status === OrderEnum.REJECTED || order?.status === OrderEnum.RECIEVED || order?.status === OrderEnum.PENDING;

  const popoverContent = (quantity: number, receivedQuantity: number) => (
    <div className={styles.popover__content}>
      <div className={styles.popover__item}>
        <p>{t("orderModal.Buyurtma")}</p>
        <p>{quantity?.toLocaleString(RU)}</p>
      </div>
      <div className={styles.popover__item}>
        <p>{t("orderModal.Qabul qilindi")}</p>
        <p>{receivedQuantity?.toLocaleString(RU)}</p>
      </div>
      <div className={styles.popover__item}>
        <p>{t("orderModal.Kutilmoqda")}</p>
        <p>{receivedQuantity > quantity ? 0 : (quantity - receivedQuantity)?.toLocaleString(RU)}</p>
      </div>
    </div>
  );

  return (
    <div className={styles.left}>
      <div className={styles.left__top}>
        <div className={styles.left__top__left}>
          {!(checkStatus || order?.status === OrderEnum.OPEN) &&
            order?.confirmation_payment !== ImpostStatusEnums.REJECTED && (
              <>
                <Button onClick={onToggleAccept} className={isAccept ? styles.is_accept : ""}>
                  {isAccept ? <CloseIcon width={16} /> : <PuzzleIcon />}
                  {t("orderModal.Qabul qilish")}
                </Button>
              </>
            )}
          <Item name="isGroupPerson" className="hidden" />
          <Item name="personKeys" className="hidden" />
        </div>
      </div>
      {isGroupPerson ? (
        <LeftGroup data={data} form={form} isLoading={isLoading} popoverContent={popoverContent} />
      ) : (
        <div className="box-border h-[1014px] overflow-auto">
          <LeftTable data={data} form={form} isLoading={isLoading} popoverContent={popoverContent} />
        </div>
      )}
    </div>
  );
};

export default Left;
