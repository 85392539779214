import React, { useLayoutEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Dropdown, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { TableRowSelection } from "antd/es/table/interface";
import { ItemType } from "antd/lib/menu/interface";
import classNames from "classnames";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import UnreadMessageAction from "features/app/components/unread-message/UnreadMessageAction";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetCustomFieldSelect, useGetMineCashSelect, useGetTableConfigs } from "features/app/service/queries";
import { generalEditKeys } from "features/app/utils/constants/generalEditData";
import { RU } from "features/app/utils/constants/languages";
import { PaymentTypeEnum } from "features/app/utils/constants/paymentTypeEnum";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { PaymentStatusEnums } from "features/app/utils/enums/paymentStatusEnums";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { PaymentModel } from "features/app/utils/models/payment/paymentModel";
import IncomeExpensePrintContent from "features/payment/components/bottom/tables/income-expense/IncomeExpensePrintContent";
import { paymentIncomeOrExpenseTableDefaultData } from "features/supply/utils/constants/paymentIncomeOrExpenseTableDefaultData";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";

import { useAppSelector } from "hooks/redux";
import { useQueryParams } from "hooks/useQueryParams";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { appReducerActions } from "store/reducers/appReducer";
import { chatActions } from "store/reducers/chatReducer";
import { paymentReducerActions } from "store/reducers/paymentReducer";

import { ConditionalRender, CopyIcon, Table } from "components";
import useDebounce from "components/use-debounce/use-debounce";

import DeleteIcon from "../../../../../app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "../../../../../app/assets/icons/DotsVerticalIcon";
import EditIcon from "../../../../../app/assets/icons/EditIcon";
import CustomAvatar from "../../../../../app/components/custom-avatar/CustomAvatar";
import OrderSortBy from "../../../../../app/components/order-sort-by/OrderSortBy";
import Pagination from "../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import TableSettings from "../../../../../app/components/table-settings/TableSettings";
import TemplateDownload from "../../../../../app/components/template-download/TemplateDownload";
import PrinterIcon from "../../../../../counterparts/assets/icons/PrinterIcon";
import FileQuestionIcon from "../../../../assets/icons/FileQuestionIcon";
import FileXIcon from "../../../../assets/icons/FileXIcon";
import { useGetPayment } from "../../../../service/queries";
import { paymentQueryKeys } from "../../../../utils/constants/paymentQueryKeys";
import { PaymentTabKeys } from "../../../../utils/constants/paymentTabKeys";
import { routeSubmodules } from "../../../../utils/constants/routeSubmodules";
import { PaymentTypeIcon } from "../../../../utils/helpers/paymentTypeIcon";
import { PaymentSourceElement } from "../../income-expense-view/PaymentSourceElement";

import "./income-expense.css";
import styles from "./paymentIncomeExpenseTable.module.scss";

type Props = {
  setPaymentDeleteModal: (
    value: React.SetStateAction<{
      visible: boolean;
      id: number;
    }>
  ) => void;
  reactToPrintContent: () => HTMLDivElement | null;
  printComponentRef: React.MutableRefObject<HTMLDivElement | null>;
};

const IncomeExpenseTable: React.FC<Props> = ({ reactToPrintContent, setPaymentDeleteModal, printComponentRef }) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const { severalSearchParams } = useQueryParams();
  const { data, isLoading } = useGetPayment();
  const { data: cashes } = useGetMineCashSelect(
    !params.get(queryParamsKeys.STATISTICS_TYPE) ||
      params.get(queryParamsKeys.STATISTICS_TYPE) === PaymentTabKeys.INCOME_EXPENSE
  );

  const { incomeOrExpenseView, selectIncomeExpense } = useAppSelector(state => state.paymentReducer);
  const oneCashParams = cashes?.length === 1 ? false : params.getAll(queryParamsKeys.CASH_IDS_ARR)?.length !== 1;

  const changeTableConfig = useChangeTableConfig(tableConfigKeys.PAYMENT_INCOME_OR_EXPENSE, true);

  const { data: tableConfigData } = useGetTableConfigs(
    tableConfigKeys.PAYMENT_INCOME_OR_EXPENSE,
    paymentIncomeOrExpenseTableDefaultData
  );
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.PAYMENT]);

  const { setGeneralEdit } = appReducerActions;
  const { setVisible: setVisibleChat } = chatActions;
  const {
    setIncomeExpenseModal,
    setSelectIncomeExpense,
    setSelectIncomeExpenseItem,
    setIncomeOrExpenseViewVisible,
    setConversionInfoModal,
    setTransferHistoryModal
  } = paymentReducerActions;

  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const paymentActions = actions("Kirim-chiqim");

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "To'lov",
    removeAfterPrint: true
  });

  const onOpenChat = (record: PaymentModel) => {
    dispatch(
      setVisibleChat({
        visible: true,
        type: "payments",
        objectId: record?.id,
        dataKeys: [
          paymentQueryKeys.PAYMENT_VIEW,
          severalSearchParams(queryParamsKeys.STATISTICS_TYPE, queryParamsKeys.CHECK_CURRENCY)
        ],
        record
      })
    );
  };

  const actionItems = (record: PaymentModel) => {
    const hasEdit = [PaymentTypeEnum.EXPENSE, PaymentTypeEnum.INCOME];

    return [
      {
        ...(hasEdit.includes(record?.type) && paymentActions.update && !record?.uysot_id
          ? {
              key: "1",
              label: (
                <>
                  <EditIcon /> {t("payment.Tahrirlash")}
                </>
              ),
              className: styles.dropdown_item,
              onClick: e => {
                e.domEvent.stopPropagation();

                dispatch(
                  setIncomeExpenseModal({
                    visible: true,
                    defaultStep: 2,
                    defaultData: record,
                    isVisibleMoreInfo: true,
                    currency: record?.currency,
                    // @ts-expect-error
                    cash: record?.cash,
                    // @ts-expect-error
                    payment_type: record?.payment_type,
                    companyPersonId: record?.company_person?.id,
                    id: record?.id,
                    type: record?.type,
                    order_ids: record?.order_ids,
                    task: record?.task
                      ? {
                          id: record?.task?.id,
                          name: record?.task?.name,
                          index: record?.task?.place,
                          sectionName: record?.section?.name,
                          projectId: record?.section?.project_id
                        }
                      : undefined
                  })
                );
              }
            }
          : {})
      },
      {
        ...(paymentActions?.create && !record?.uysot_id
          ? {
              key: "2",
              className: styles.dropdown_item,
              label: (
                <>
                  <CopyIcon />
                  {t("payment.Nusxa olish")}
                </>
              ),
              onClick: e => {
                e.domEvent.stopPropagation();

                dispatch(
                  setIncomeExpenseModal({
                    visible: true,
                    isClone: true,
                    defaultStep: 2,
                    defaultData: record,
                    isVisibleMoreInfo: false,
                    currency: record?.currency,
                    // @ts-expect-error
                    cash: record?.cash,
                    // @ts-expect-error
                    payment_type: record?.payment_type,
                    companyPersonId: record?.company_person?.id,
                    type: record?.type,
                    order_ids: record?.order_ids,
                    task: record?.task
                      ? {
                          id: record?.task?.id,
                          name: record?.task?.name,
                          index: record?.task?.place,
                          sectionName: record?.section?.name,
                          projectId: record?.section?.project_id
                        }
                      : undefined
                  })
                );
              }
            }
          : {})
      },
      {
        ...(paymentActions.chatView
          ? {
              key: "3",
              label: <UnreadMessageAction count={record?.unread_message_count} />,
              className: styles.dropdown_item,
              onClick: e => {
                e.domEvent.stopPropagation();
                onOpenChat(record);
              }
            }
          : {})
      },
      {
        ...(paymentActions.delete && !record?.uysot_id
          ? {
              key: "4",
              label: (
                <>
                  <DeleteIcon /> {t("payment.O’chirish")}
                </>
              ),
              className: styles.dropdown_item,
              onClick: e => {
                e.domEvent.stopPropagation();

                setPaymentDeleteModal({ id: record?.id, visible: true });
              }
            }
          : {})
      },
      {
        ...(record?.status !== PaymentStatusEnums.CANCEL
          ? {
              key: "5",
              label: (
                <>
                  <PrinterIcon /> {t("payment.Chop etish")}
                </>
              ),
              className: styles.dropdown_item,
              onMouseEnter: () => {
                if (record?.id !== incomeOrExpenseView?.record?.id) {
                  dispatch(
                    setIncomeOrExpenseViewVisible({
                      visible: false,
                      record
                    })
                  );
                }
              },
              onClick: e => {
                e.domEvent.stopPropagation();

                handlePrint();
              }
            }
          : {})
      },
      {
        ...(paymentActions.upload
          ? {
              key: "6",
              label: (
                <TemplateDownload object_id={record?.id} location={CustomFieldLocationEnum.PAYMENT} styles={styles} />
              )
            }
          : {})
      }
    ] as ItemType[];
  };

  const [tableColumns, setColumns] = useState<ColumnsType<PaymentModel>>([]);

  const columns: ColumnsType<PaymentModel> = [
    {
      title: `${t("payment.Turi")}`,
      render: (__: any, record: PaymentModel) => PaymentTypeIcon(record?.type, record?.status, t),
      key: "type",
      width: 50,
      fixed: "left",
      className: "counterparts-payment-type",
      align: "center"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_DATE} title={t("payment.Sana")} />,
      dataIndex: "date",
      key: "date",
      width: 130,
      className: "counterparts-payment-date"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_AMOUNT} title={t("payment.Summa")} />,
      render: (__: any, record: PaymentModel) => (
        <>
          {record?.amount?.toLocaleString(RU)} {record?.currency?.symbol}
        </>
      ),
      key: "amount",
      width: 250,
      className: "counterparts-payment-amount"
    },
    {
      ...(oneCashParams && {
        title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_CASH_NAME} title={t("payment.Kassa")} />,
        render: (__: any, record: PaymentModel) => record?.cash?.name || "-"
      }),
      key: "cash.name",
      width: 220,
      className: "counterparts-payment-cash.name"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_PROJECT_NAME} title={t("payment.Loyiha")} />,
      render: (__: any, record: PaymentModel) => record?.project?.name || "-",
      key: "project.name",
      width: 220,
      className: "counterparts-payment-project.name"
    },
    {
      title: `${t("payment.To'lov turi")}`,
      render: (__: any, record: PaymentModel) => (
        <div
          className={styles.payment_type}
          style={{
            color: record?.payment_type?.color,
            background: `${record?.payment_type?.color}20`
          }}
        >
          {record?.payment_type?.name || "-"}
        </div>
      ),
      key: "payment_type.name",
      width: 150,
      className: "counterparts-payment-payment_type.name"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_FINANCIAL_NAME} title={t("payment.Manba")} />,
      render: (__: any, record: PaymentModel) =>
        PaymentSourceElement({
          isViewKey: true,
          record
        }),
      key: "user.full_name",
      width: 250,
      className: "counterparts-payment-creator.full_name"
    },
    {
      title: `${t("payment.Xodim")}`,
      render: (__: any, record: PaymentModel) => (
        <ConditionalRender if={record?.creator?.full_name} else="--">
          <CustomAvatar name={record?.creator?.full_name} image={record?.creator?.image} />
        </ConditionalRender>
      ),
      width: 80,
      key: "creator.full_name",
      className: "payment-creator.full_name"
    },
    {
      title: `${t("payment.Izoh")}`,
      render: (_value: any, record: PaymentModel) => record?.description,
      key: "description",
      width: 200,
      className: "payment-description"
    }
  ];

  const rowSelection: TableRowSelection<PaymentModel> | undefined = {
    type: "checkbox",
    selectedRowKeys: selectIncomeExpense?.map(item => item?.id),
    onSelect: record => {
      const isSomeParty = selectIncomeExpense?.some(item => item?.id === record?.id);

      if (!isSomeParty) {
        dispatch(setSelectIncomeExpense([...selectIncomeExpense, record]));
      } else {
        dispatch(setSelectIncomeExpense(selectIncomeExpense?.filter(item => item?.id !== record?.id)));
      }
    },
    onSelectAll: (selected, selectedRows) => {
      if (selected) {
        selectedRows?.forEach(item => {
          dispatch(setSelectIncomeExpenseItem(item));
        });
      } else {
        dispatch(setSelectIncomeExpense([]));
      }
    },
    onChange: () => {
      dispatch(
        setGeneralEdit({
          isView: true,
          key: generalEditKeys.PAYMENT,
          customFieldKeys: [CustomFieldLocationEnum.PAYMENT]
        })
      );
    }
  };

  const onDetailIncomeOrExpense = (record: PaymentModel) => ({
    onClick: () => {
      if (record?.type === PaymentTypeEnum.EXCHANGE) {
        dispatch(setConversionInfoModal(record));
      } else if (record?.type === PaymentTypeEnum.TRANSFER) {
        dispatch(setTransferHistoryModal(record));
      } else {
        dispatch(
          setIncomeOrExpenseViewVisible({
            visible: true,
            record
          })
        );
      }
    }
  });

  const saveColumns = () => {
    const columnsToSave = tableColumns?.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutate({
      key: tableConfigKeys.PAYMENT_INCOME_OR_EXPENSE,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800,timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<PaymentModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  useLayoutEffect(() => {
    if (tableConfigData && customFields) {
      setColumns([
        ...filterColumns({
          columns: columns as never,
          customFields,
          tableConfig: tableConfigData
        }),
        {
          title: (
            <div className="flex items-center justify-end">
              <TableSettings
                isNeedSize
                locations={[CustomFieldLocationEnum.PAYMENT]}
                defaultData={paymentIncomeOrExpenseTableDefaultData}
                configKey={tableConfigKeys.PAYMENT_INCOME_OR_EXPENSE}
              />
            </div>
          ),
          render: (__: any, record: PaymentModel) => (
            <div className="flex items-center justify-end gap-1">
              <ConditionalRender if={record?.status === PaymentStatusEnums.PASSIVE}>
                <Tooltip title={t("payment.Qoralama")}>
                  <span className={styles.passive}>
                    <FileQuestionIcon />
                  </span>
                </Tooltip>
              </ConditionalRender>
              <ConditionalRender if={record?.status === PaymentStatusEnums.CANCEL}>
                <Tooltip title="Bekor qilingan">
                  <span className={styles.cancel}>
                    <FileXIcon />
                  </span>
                </Tooltip>
              </ConditionalRender>
              <Dropdown
                menu={{ items: actionItems(record).filter(item => item?.key) }}
                trigger={["click", "contextMenu"]}
                overlayStyle={{ zIndex: 1000 }}
              >
                <div
                  style={{ width: 24 }}
                  onClick={e => e.stopPropagation()}
                  className={classNames("flex h-7 cursor-pointer items-center justify-center", {
                    hasBadge: !!record?.unread_message_count
                  })}
                >
                  <DotsVerticalIcon />
                </div>
              </Dropdown>
            </div>
          ),
          fixed: "right",
          width: 100,
          className: "counterparts-payment-action"
        }
      ]);
    }
  }, [tableConfigData, customFields]);

  return (
    <div className={styles.income_expense}>
      <div className={styles.top}>
        <Table<PaymentModel>
          onChangeColumns={onChangeColumns}
          pagination={false}
          dataSource={data?.data}
          columns={tableColumns?.filter(col => Object.keys(col)?.length > 0)}
          rowKey={row => row?.id}
          onRow={onDetailIncomeOrExpense}
          rowClassName="c_p"
          rootClassName={styles.root_table}
          rowSelection={rowSelection}
          scroll={{ y: "65vh" }}
          locale={{
            emptyText: <TableEmpty />
          }}
          loading={{
            spinning: isLoading,
            indicator: LoadingIndicator
          }}
        />
      </div>
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: data?.total
          }}
          configKey={tableConfigKeys.PAYMENT_INCOME_OR_EXPENSE}
        />
      </div>
      <IncomeExpensePrintContent tableColumns={tableColumns} ref={printComponentRef} />
    </div>
  );
};

export default IncomeExpenseTable;
