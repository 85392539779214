import { useQuery } from "@tanstack/react-query";

import useGetTableSize from "../../../hooks/useGetTableSize";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { queryParamsKeys } from "../../app/utils/constants/queryParamsKeys";
import { tableConfigKeys } from "../../app/utils/constants/tableConfigKeys";
import $api from "../../app/utils/helpers/axiosInstance";
import { PaginationType } from "../../app/utils/models/PaginationType";
import { impostEndPoints } from "../utils/constants/impostEndPoints";
import { impostQueryKeys } from "../utils/constants/impostQueryKeys";
import { tabKeys } from "../utils/constants/tabKeys";
import { PartImpostModel } from "../utils/models/partImpostModel";
import { PaymentImpostModel } from "../utils/models/paymentImpostModel";
import { WorksInventoryImpostModel } from "../utils/models/worksImpostModel";

export function useGetWorksImpost() {
  const { reqQueryParam, queries, severalSearchParams } = useQueryParams();
  let url = impostEndPoints.WORKS;
  const searchParams = severalSearchParams(queryParamsKeys.TAB);

  if (searchParams) {
    url += `?${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.SIZE,
      queryParamsKeys.CURRENCY_ID,
      queryParamsKeys.MIN_TOTAL_AMOUNT,
      queryParamsKeys.MAX_TOTAL_AMOUNT,
      queryParamsKeys.MIN_DATE,
      queryParamsKeys.MAX_DATE,
      queryParamsKeys.CONFIRMATION_PAYMENTS_ARR,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.USER_IDS_ARR,
      queryParamsKeys.COMPANY_PERSON_IDS_ARR
    )}`;
  }

  return useQuery<PaginationType<WorksInventoryImpostModel[]>>(
    [impostQueryKeys.WORKS, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !queries()[queryParamsKeys.TAB] || queries()[queryParamsKeys.TAB] === tabKeys.WORK
    }
  );
}

export function useGetInventoryImpost() {
  const { searchParams, reqQueryParam, queries } = useQueryParams();
  let url = impostEndPoints.INVENTORIES;

  if (searchParams) {
    url += `?${reqQueryParam(queryParamsKeys.PAGE, queryParamsKeys.SEARCH)}`;
  }

  return useQuery<PaginationType<WorksInventoryImpostModel[]>>(
    [impostQueryKeys.INVENTORIES, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !queries()[queryParamsKeys.TAB] || queries()[queryParamsKeys.TAB] === tabKeys.WORK
    }
  );
}

export function useGetPartsImpost() {
  const { reqQueryParam, severalSearchParams, queries } = useQueryParams();
  const searchParam = severalSearchParams(queryParamsKeys.TAB);

  let url = useGetTableSize({
    endpoint: impostEndPoints.PARTS,
    tableConfigKey: tableConfigKeys.IMPOST_ORDER_CONFIG
  });

  if (searchParam) {
    url += `&${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.CURRENCY_ID,
      queryParamsKeys.MIN_AMOUNT,
      queryParamsKeys.MAX_AMOUNT,
      queryParamsKeys.MIN_DELIVERY_DATE,
      queryParamsKeys.MAX_DELIVERY_DATE,
      queryParamsKeys.MIN_PAYMENT_DATE,
      queryParamsKeys.MAX_PAYMENT_DATE,
      queryParamsKeys.MIN_ORDERED_DATE,
      queryParamsKeys.MAX_ORDERED_DATE,
      queryParamsKeys.COMPANY_PERSON_IDS_ARR,
      queryParamsKeys.AGENT_IDS_ARR,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.CONFIRMATION_PAYMENTS_ARR,
      queryParamsKeys.CUSTOM_FIELDS_ARR,
      queryParamsKeys.PAYMENT_TYPE_IDS_ARR,
      queryParamsKeys.STATUSES_ARR,
      queryParamsKeys.VAT_ARR,
      queryParamsKeys.DISCOUNT_ARR
    )}`;
  }

  return useQuery<PaginationType<PartImpostModel[]>>(
    [impostQueryKeys.PARTS, searchParam],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !queries()[queryParamsKeys.TAB] || queries()[queryParamsKeys.TAB] === tabKeys.ORDER
    }
  );
}

export function useGetPartImpost(enable?: boolean, id?: number) {
  return useQuery<PartImpostModel>(
    [impostQueryKeys.PARTS_DETAIL, id],
    async () => {
      const res = await $api.get(`${impostEndPoints.PARTS}?id=${id}`);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: enable && !!id
    }
  );
}

export function useGetPaymentsImpost() {
  const { reqQueryParam, queries, severalSearchParams } = useQueryParams();
  let url = impostEndPoints.PAYMENTS;
  const searchParams = severalSearchParams(queryParamsKeys.TAB);

  if (searchParams) {
    url += `?${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SEARCH,

      queryParamsKeys.SIZE,
      queryParamsKeys.CURRENCY_ID,
      queryParamsKeys.MIN_AMOUNT,
      queryParamsKeys.MAX_AMOUNT,
      queryParamsKeys.MIN_DATE,
      queryParamsKeys.MAX_DATE,
      queryParamsKeys.STATUSES_ARR,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.PAYMENT_TYPE_IDS_ARR,
      queryParamsKeys.COMPANY_PERSON_IDS_ARR,
      queryParamsKeys.CREATORS_IDS_ARR,
      queryParamsKeys.FINANCIAL_IDS_ARR
    )}`;
  }

  return useQuery<PaginationType<PaymentImpostModel[]>>(
    [impostQueryKeys.PAYMENTS, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !queries()[queryParamsKeys.TAB] || queries()[queryParamsKeys.TAB] === tabKeys.PAYMENT
    }
  );
}

export function useGetImpostCounts() {
  return useQuery<Record<tabKeys, number>>(
    [impostQueryKeys.IMPOST_ALL_COUNTS],
    async () => {
      const res = await $api.get(impostEndPoints.ALL_COUNTS);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      refetchOnMount: "always"
    }
  );
}
