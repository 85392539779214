import React, { useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { DatePicker, Form, FormInstance, Popover, Select } from "antd";
import { parseLocaledString } from "features/app/utils/helpers/parseLocaledString";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../hooks/redux";
import DatepickerSuffixIcon from "../../../../../app/assets/icons/DatepickerSuffixIcon";
import ModalContentCustomFields from "../../../../../app/components/modal-content-custom-fields/ModalContentCustomFields";
import { useGetCustomFieldSelect, useGetUsersSelect } from "../../../../../app/service/queries";
import { dayjsFormats } from "../../../../../app/utils/constants/dayjsFormats";
import { queryKeys } from "../../../../../app/utils/constants/queryKeys";
import { UnitModel } from "../../../../../app/utils/constants/unitModel";
import { CustomFieldLocationEnum } from "../../../../../app/utils/enums/customFieldLocationEnum";
import { isEmptyArr } from "../../../../../app/utils/helpers/isEmptyArr";
import { useGetOneOrder } from "../../../../service/query";

import styles from "./right.module.scss";

const { Item } = Form;
const { Option } = Select;

type Props = {
  form: FormInstance;
};

const Right: React.FC<Props> = ({ form }) => {
  const qc = useQueryClient();
  const { i18n, t } = useTranslation();

  const { visible, data: order } = useAppSelector(state => state.warehouseOrderReducer.view);

  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.ORDER]);

  const { data: agents } = useGetUsersSelect(visible);
  const { data: warehouseProducts } = useGetOneOrder(order?.id, visible);
  const unitData: UnitModel[] | undefined = qc.getQueryData([queryKeys.UNITS]);

  const sortOrder =
    warehouseProducts?.reduce((prev: { [key: string]: number }, current) => {
      const findUnit = unitData?.find(unit => unit?.id === current?.unit?.id);

      if (findUnit?.name[i18n.language]) {
        prev[findUnit?.name[i18n.language]] =
          (prev[findUnit?.name[i18n.language]] ?? 0) + parseLocaledString(String(current?.quantity ?? 0));
      }
      return prev;
    }, {}) || {};

  const sortOrderKeys = Object.keys(sortOrder);

  const allUnitText = useMemo(() => {
    return (
      <div className={styles.units}>
        {sortOrderKeys?.map((key, index) => (
          <div className={styles.units__item} key={key}>
            <p>{sortOrder[key]}</p>
            <span>{key}</span>
            {index + 1 !== sortOrderKeys?.length && <p>,</p>}
          </div>
        ))}
      </div>
    );
  }, [[sortOrderKeys]]);

  return (
    <div className={styles.right}>
      <div className={styles.right__top}>
        <Item label={t("Monitoring.Vositachi")} name="agent_id">
          <Select disabled suffixIcon={null} placeholder={t("orderModal.Tanlanmagan")}>
            {agents?.map(item => (
              <Option value={item.id} key={item.id}>
                {item?.full_name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item label={t("Monitoring.Yetkazish sanasi")} name="delivery_date">
          <DatePicker
            disabled
            format={dayjsFormats.DATE}
            placeholder={t("orderModal.Tanlanmagan")}
            suffixIcon={<DatepickerSuffixIcon />}
          />
        </Item>
        <Item label={t("Monitoring.To'lash sanasi")} name="payment_date">
          <DatePicker
            disabled
            format={dayjsFormats.DATE}
            placeholder={t("orderModal.Tanlanmagan")}
            suffixIcon={<DatepickerSuffixIcon />}
          />
        </Item>
        {!isEmptyArr(customFields) && (
          <ModalContentCustomFields form={form} disabled={true} visible={visible} customFields={customFields} />
        )}
      </div>
      <div className={styles.right__bottom}>
        <div className={styles.total}>
          <div className={styles.total__item}>
            <h4>{t("Monitoring.Jami mahsulotlar")}:</h4>
            <Popover title={allUnitText} placement="left">
              <p>
                {sortOrderKeys?.length ?? 0} {t("Monitoring.birlik")}
              </p>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Right;
