import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, FormInstance, Select } from "antd";
import { useTranslation } from "react-i18next";

import { useGetUsersSelect } from "features/app/service/queries";
import { selectFilterOption } from "features/app/utils/helpers/selectFilterOption";
import { useAppSelector } from "hooks/redux";

import { settingsActions } from "store/reducers/settingsReducer";

import SelectSuffixIcon from "../../../../../../app/assets/icons/SelectSuffixIcon";
import SelectNotContent from "../../../../../../app/components/select-not-content/SelectNotContent";

const { Item } = Form;
const { Option } = Select;

const PartyAgentSelect: React.FC<{ form: FormInstance }> = ({ form }) => {
  const dispatch = useDispatch();
  const { setAdminModal } = settingsActions;
  const [searchValue, setSearchValue] = useState<string>();
  const { t } = useTranslation();

  const { visible, isView } = useAppSelector(state => state.supplyReducer.partyModal);

  const { data: agents } = useGetUsersSelect(visible);

  const onAddAdmin = () => {
    dispatch(
      setAdminModal({
        visible: true,
        initialName: searchValue,
        afterFunc: (id: number) => {
          form.setFieldValue("agent_id", id);
        }
      })
    );
  };

  const onSearch = (e: string) => {
    setSearchValue(e);
  };

  return (
    <Item label={t("partyModal.Vositachi")} name="agent_id">
      <Select
        showSearch
        allowClear
        disabled={isView}
        onSearch={onSearch}
        searchValue={searchValue}
        filterOption={selectFilterOption}
        placeholder={isView ? t("partyModal.Tanlanmagan") : t("partyModal.Tanlang")}
        suffixIcon={isView ? null : <SelectSuffixIcon />}
        notFoundContent={<SelectNotContent title={t("partyModal.Vositachi")} action={onAddAdmin} />}
      >
        {agents?.map(item => (
          <Option
            value={item.id}
            key={item.id}
            props={{
              name: item?.full_name
            }}
          >
            {item?.full_name}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default PartyAgentSelect;
